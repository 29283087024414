import InputBase from "@mui/material/InputBase";
import { ReactComponent as SearchIcon } from "common/images/Icon-Magnifying-Glass.svg";

const SearchBar = ({ onChange, ...props }) => {
  return (
    <InputBase
      startAdornment={
        <SearchIcon
          style={{
            marginRight: 10,
          }}
        />
      }
      placeholder="Search"
      onChange={onChange}
      {...props}
    />
  );
};

export default SearchBar;
