import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import _ from "lodash";

export default function PrivateRoutes() {
  const token = Cookies.get("elevadeToken");
  const { userProfile } = useSelector((state) => state.user);
  let userId = userProfile == null ? false : true;
  return <>{!userId || _.isEmpty(token) ? <Navigate to="/login" /> : <Outlet />};</>;
}
