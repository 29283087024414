import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Link } from "@mui/material";
import { DashboardMenu } from "./SidebarIconsList";
import styles from "./Sidebar.module.css";
import MUIAvatar from "common/components/avatar/MUIAvatar";
import IconArrowUpGrey from "common/images/Icon-ArrowUpGrey.svg";
import { useSelector } from "react-redux";

const DashboardSidebarIcon = ({ openMenu, isOpen }) => {
  const { userDetails } = useSelector((state) => state.user);
  const companyName = userDetails?.company?.registeredName;
  const companyLogo = userDetails?.company?.logo;
  const location = useLocation();
  const [showArrow, setShowArrow] = useState(false);

  function iconStatus(item) {
    if (location?.pathname === item?.url) {
      return item?.activeIcon;
    } else {
      return item?.icon;
    }
  }

  useEffect(() => {
    (isOpen || openMenu) && setTimeout(() => setShowArrow(true), 300);
    !isOpen && !openMenu && setShowArrow(false);
  }, [openMenu, isOpen]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          textAlign: "center",
          marginBottom: "48px",
        }}
      >
        {companyName ? (
          <>
            <MUIAvatar
              name={companyName}
              className="avatar-dashboard"
              style={{
                marginTop: "6px",
                marginLeft: "-3px",
                border: "1px solid #E8E8E8",
              }}
              src={companyLogo}
              size={36}
            ></MUIAvatar>
            <span
              style={{
                fontFamily: "Gotham-Medium",
                fontSize: 12,
                color: "#394458",
              }}
            >
              {(isOpen || openMenu) && companyName}
            </span>
          </>
        ) : (
          <MUIAvatar className="avatar-dashboard" size={36}></MUIAvatar>
        )}
      </Box>
      {(isOpen || openMenu) &&
        DashboardMenu.map((value, index) => {
          return (
            value.visible &&
            (isOpen || openMenu) && (
              <>
                <div className={styles.parentMenuGrid} key={index}>
                  <div className={styles.labelParent}>{value.label}</div>
                  <div className={styles.labelArrow}>
                    {showArrow && <img src={IconArrowUpGrey} alt="icon-arrow-up" />}
                  </div>
                </div>
                <div>
                  <ul style={{ padding: 0, textAlign: "left" }}>
                    {DashboardMenu.map((value) => {
                      return value?.children.map((item, index) => {
                        return item?.visible ? (
                          <Link href={item.url} underline="none" color="inherit">
                            <div
                              style={{ display: "flex", marginBottom: "20px" }}
                              key={index}
                              className={`${styles.secondLayerButton} ${
                                location.pathname === item?.url ? styles.secondLayerButtonActive : ""
                              }`}
                            >
                              <li style={{ listStyle: "none", marginLeft: "12px" }}>
                                <img
                                  src={iconStatus(item)}
                                  alt="icon"
                                  className={`
                                  ${
                                    item?.disable
                                      ? styles.sidebarIconDisable
                                      : location.pathname === item?.url
                                      ? styles.sidebarIconActive
                                      : styles.sidebarIcon
                                  }
                                `}
                                />
                              </li>
                              <li
                                style={{ marginTop: "12px", listStyle: "none", marginLeft: "10px", fontSize: "14px" }}
                              >
                                {item?.label}
                              </li>
                            </div>
                          </Link>
                        ) : item?.label === "seperator" ? (
                          <hr style={{ marginTop: "2rem", marginBottom: "2rem" }}></hr>
                        ) : (
                          <></>
                        );
                      });
                    })}
                  </ul>
                </div>
              </>
            )
          );
        })}
      {!isOpen && !openMenu && (
        <ul style={{ padding: 0, textAlign: "left" }}>
          {DashboardMenu.map((value) => {
            return value?.children.map((item, index) => {
              return item?.visible ? (
                <div style={{ display: "flex" }} key={index}>
                  <li style={{ marginBottom: "20px", listStyle: "none", marginLeft: "12px" }}>
                    <img
                      src={iconStatus(item)}
                      alt="icon"
                      className={`
                    ${
                      item?.disable
                        ? styles.sidebarIconDisable
                        : location.pathname === item?.url
                        ? styles.sidebarIconActive
                        : styles.sidebarIcon
                    }
                  `}
                    />
                  </li>
                  {isOpen && (
                    <li style={{ marginTop: "10px", listStyle: "none", marginLeft: "10px", fontSize: "14px" }}>
                      {item?.label}
                    </li>
                  )}
                </div>
              ) : item?.label === "seperator" ? (
                <hr style={{ marginTop: "2rem", marginBottom: "2rem" }}></hr>
              ) : (
                <></>
              );
            });
          })}
        </ul>
      )}
    </>
  );
};

export default DashboardSidebarIcon;
