import React from "react";
import { Link, useLocation } from "react-router-dom";
import Linkedin from "common/images/LinkedInLogo-Border.svg";
import Youtube from "common/images/YouTubeLogo-Border.svg";
import styles from "./Footer.module.css";
import { Container, Divider, Grid, useMediaQuery } from "@mui/material";
import ElevadeLogo from "common/images/Elevade-Logo-White.svg";
import IconExternalLinkWhite from "common/images/Icon-ExternalLinkWhite.svg";

const Footer = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:900px)");

  const email = "mailto:elevade_support@ade.aero";
  const footerLocation =
    location.pathname === "/" ||
    location.pathname === "/about/people" ||
    location.pathname === "/about/fleet" ||
    location.pathname === "/about/cabin-monitoring";

  return (
    // <Fragment>
    footerLocation && (
      <footer
        id="footer-main"
        className={`${styles.footer}`}
        /* style={{ paddingLeft: isMobile && location.pathname !== "/fleet" ? "24px" : "" }} */
        //style={{ paddingLeft: isMobile ? "24px" : "" }}
      >
        <Divider orientation="horizontal" light variant="middle" />
        <Grid container columns={5}>
          {/* ----------- SOLUTIONS ----------  */}
          <Grid item xs={12} sm={12} md={12} lg={1} xl={1} style={{ textAlign: isMobile ? "center" : "" }}>
            <span className={styles.textSmallBold} style={{ color: "#fff" }}>
              Solutions
            </span>
            <ul className={`${styles.textSmall} ${styles.ulFooter}`} style={{ color: "#fff" }}>
              <li>
                <Link
                  reloadDocument
                  to="https://airasia.elevade.io/people"
                  target="blank_"
                  style={{ color: "#ffffff" }}
                >
                  PEOPLE
                  <img src={IconExternalLinkWhite} alt="" style={{ marginTop: "-2px", marginLeft: "4px" }} />
                </Link>
              </li>
              <li>
                <Link reloadDocument to="https://airasia.elevade.io/fleet" target="blank_" style={{ color: "#ffffff" }}>
                  FLEET
                  <img src={IconExternalLinkWhite} alt="" style={{ marginTop: "-2px", marginLeft: "4px" }} />
                </Link>
              </li>
              {/* <li>
                <Link
                  to={{ pathname: "https://ade.aero/aerotrade/marketplace" }}
                  target="blank_"
                  style={{ color: "#ffffff" }}
                >
                  AEROTRADE <img src={IconExternalLinkWhite} alt="" style={{ marginTop: "-2px", marginLeft: "4px" }} />
                </Link>
              </li> */}
            </ul>
          </Grid>

          {/* ----------- COMPANY ----------  */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={1}
            xl={1}
            sx={{ paddingTop: isMobile ? "64px" : "" }}
            style={{ textAlign: isMobile ? "center" : "" }}
          >
            <span className={styles.textSmallBold} style={{ color: "#fff" }}>
              Company
            </span>
            <ul className={`${styles.textSmall} ${styles.ulFooter}`} style={{ color: "#fff" }}>
              {/* <li>
                <Link
                  to={{ pathname: "https://ade.aero/about-us/who-we-are" }}
                  target="blank_"
                  style={{ color: "#ffffff" }}
                >
                  About Us <img src={IconExternalLinkWhite} alt="" style={{ marginTop: "-2px", marginLeft: "4px" }} />
                </Link>
              </li> */}
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    window.location.href = email;
                    e.preventDefault();
                  }}
                  style={{ color: "#ffffff" }}
                >
                  Talk to Us
                </Link>
              </li>
            </ul>
          </Grid>

          {/* ----------- QUICK LINKS ----------  */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={1}
            xl={1}
            sx={{ paddingTop: isMobile ? "64px" : "" }}
            style={{ textAlign: isMobile ? "center" : "" }}
          >
            <span className={styles.textSmallBold} style={{ color: "#fff" }}>
              Other Products/Links{" "}
              <img src={IconExternalLinkWhite} alt="" style={{ marginTop: "-2px", marginLeft: "4px" }} />
            </span>
            <ul className={`${styles.textSmall} ${styles.ulFooter}`} style={{ color: "#fff" }}>
              <li>
                <a href="https://ade.aero/aerotrade/marketplace" target="blank_" style={{ color: "#ffffff" }}>
                  AEROTRADE
                </a>
              </li>
              <li>
                <a href="https://ade.aero" target="blank_" style={{ color: "#ffffff" }}>
                  ADE.AERO
                </a>
              </li>
            </ul>
          </Grid>

          {/* ----------- SOCMED ----------  */}

          {/* <Grid item xs={0} sm={0} md={0} lg={1} xl={1}></Grid>
          <Grid item xs={6} sm={6} md={6} lg={1} xl={1}>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "" : "column",
                alignItems: "center",
                gap: "24px",
                paddingTop: isMobile ? "40px" : "0px",
              }}
            >
              <img src={ElevadeLogo} alt="Elevade Logo" width={isMobile ? 100 : 130} />
              <div className={styles.text} style={{ margin: isMobile ? "0 20px" : "0px" }}>
                Follow us on:
                <Link
                  to={{ pathname: "https://www.linkedin.com/company/ade-aero/mycompany/" }}
                  target="blank_"
                  style={{ color: "#ffffff" }}
                >
                  <img src={Linkedin} alt="LinkedIn" style={{ padding: "0 10px" }} />
                </Link>
              </div>
            </div>
            <div className={styles.text} style={{ marginTop: "24px" }}>
              &copy; {new Date().getFullYear()} All rights reserved by
              <br />
              Asia Digital Engineering Sdn Bhd
            </div>
          </Grid> */}
          <Grid item xs={0} sm={0} md={0} lg={1} xl={1}></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={1}
            xl={1}
            sx={{ paddingTop: isMobile ? "88px" : "" }}
            style={{ textAlign: isMobile ? "center" : "" }}
          >
            <ul className={`${styles.text} ${styles.ulFooter}`} style={{ color: "#fff" }}>
              <span>
                <img src={ElevadeLogo} alt="Elevade Logo" width={130} />
              </span>
              <li style={{ marginTop: "24px" }}>
                Follow us on:
                <a href="https://www.linkedin.com/company/ade-aero" target="blank_" style={{ color: "#ffffff" }}>
                  <img src={Linkedin} alt="LinkedIn" style={{ padding: "0 10px", marginBottom: "-7px" }} />
                </a>
                <a href="https://www.youtube.com/@ade7530" target="blank_" style={{ color: "#ffffff" }}>
                  <img src={Youtube} alt="YouTube" style={{ marginBottom: "-7px" }} />
                </a>
              </li>
              <li className={styles.text} style={{ marginTop: "24px" }}>
                &copy; {new Date().getFullYear()} All rights reserved by
                <br />
                Asia Digital Engineering Sdn Bhd
              </li>
            </ul>
          </Grid>
        </Grid>
      </footer>
    )
    // </Fragment>
  );
};

export default Footer;
