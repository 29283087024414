import * as React from "react";
import Avatar from "@mui/material/Avatar";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name, props) {
  return name?.length > 0
    ? {
        sx: {
          bgcolor: props.bgcolor || stringToColor(name),
          width: props.width,
          height: props.height,
          fontSize: props.fontSize,
        },
        children: name.includes(" ") ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}` : `${name.slice(0, 2)}`,
      }
    : "";
}
const MUIAvatar = ({ src, name, ...props }) => {
  return <Avatar src={src ? src : null} {...stringAvatar(name?.toUpperCase(), props)} />;
};

export default MUIAvatar;
