import React from "react";
import { Box } from "@mui/material";
import styles from "./Sidebar.module.css";
import ElevadeSidebar from "./ElevadeSidebar";
// import { ElevadeSidebar } from "@ade/global-component-web";
import DashboardSidebar from "./DashboardSidebar";
import { useLocation } from "react-router";

const Sidebar = () => {
  const location = useLocation();
  return (
    location.pathname !== "/callback" &&
    location.pathname !== "/login" &&
    location.pathname !== "/about/cabin-monitoring" &&
    location.pathname !== "/" && (
      <Box className={styles.container}>
        <ElevadeSidebar />
        <DashboardSidebar />
      </Box>
    )
  );
};

export default Sidebar;
