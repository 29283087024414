import React, { useEffect, useState } from "react";
import styles from "./Notifications.module.css";
import IconArrowLeft from "common/images/Icon-BreadcrumbArrowLeft.svg";
import { formatDate, makeAPIRequest } from "common/helpers/Helpers";
import { Link } from "react-router-dom";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import DropDown from "common/components/dropDown/DropDown";
import { ReactComponent as FilterIcon } from "common/images/Icon-Filter.svg";
import { MODULES } from "common/constant";
import SearchBar from "common/components/searchBar/SearchBar";
import Spacer from "common/components/spacer/Spacer";
import ActiveFilters from "common/components/activeFilters/ActiveFilters";

const Notifications = () => {
  const [isActive, setIsActive] = useState(1);
  const [isData, setIsData] = useState([]);
  const [isFilteredData, setIsFilteredData] = useState(isData);
  const [moduleValue, setModuleValue] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchModuleValue, setSearchModuleValue] = useState("");
  const [allFilters, setAllFilters] = useState({});

  useEffect(() => {
    let tempData = isData;
    if (isActive === 2) {
      tempData = isData.filter((e) => e.read === false);
    }

    if (moduleValue.length > 0) {
      tempData = tempData.filter((item) =>
        moduleValue.find((module) => {
          if (typeof module === "string") {
            return module.toLocaleUpperCase() === item.module.toLocaleUpperCase();
          }
          return module.value.toLocaleUpperCase() === item.module.toLocaleUpperCase();
        }),
      );
    }

    if (searchValue) {
      tempData = tempData.filter(
        (item) =>
          item.title.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase()) ||
          item.content.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase()),
      );
    }
    setIsFilteredData(tempData);
  }, [isData, isActive, moduleValue, searchValue]);

  const DotRed = (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
      <circle cx="3" cy="3" r="3" fill="#E95048" />
    </svg>
  );

  function getTotalRead(bool) {
    return isFilteredData.filter((e) => e.read === bool).length;
  }

  async function handleNotificationClick(notification) {
    if (!notification.read) {
      try {
        const result = await makeAPIRequest("put", `api/notification/markAsRead/${notification.id}`);
        await getNotification();
      } catch (error) {
        console.log("Error when marking notification as read: ", error);
      }
    }

    if (notification.redirectUrl) {
      window.location.href = notification.redirectUrl;
    }
  }

  async function handleMarkAllAsRead() {
    try {
      const result = await makeAPIRequest("put", "api/notification/markAllAsRead");

      await getNotification();
    } catch (error) {
      console.log("Error when marking all as read: ", error);
    }
  }

  async function getNotification() {
    try {
      const result = await makeAPIRequest("post", "api/notification/getByFilter", {});

      const formattedResult = result.map((item) => ({
        id: item.id,
        module: item.module,
        title: item.title,
        content: item.message,
        datetime: item.createdAt,
        read: item.read,
        redirectUrl: item.redirectUrl,
        month: new Date(item.createdAt).getMonth(),
        year: new Date(item.createdAt).getFullYear(),
        dateString: new Date(item.createdAt)
          .toLocaleString("default", { month: "long", year: "numeric" })
          .toLocaleUpperCase(),
      }));

      setIsData(formattedResult);
    } catch (error) {
      console.log("Error while fetching notification: ", error);
    }
  }

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <div className={styles.container}>
      <Link to="/main/" className={styles.breadcrumbs} style={{ textDecoration: "none" }}>
        <img src={IconArrowLeft} alt="icon-arrow-left" height={20} />
        <div className={styles.breadcrumbsTitle}>Notifications</div>
        {/* <div className={styles.noti}>{isCountNoti}</div> */}
      </Link>
      <Spacer height={22} />
      <Grid container>
        <Grid item>
          <SearchBar
            placeholder="Search"
            onChange={(evt) => {
              setSearchValue(evt.target.value);
            }}
          />
        </Grid>
        <Grid item ml={"auto"}>
          <DropDown
            onSearch={(value) => {
              setSearchModuleValue(value);
            }}
            onChange={(value) => {
              setModuleValue(value);

              const tempFilters = {
                ...allFilters,
                module: value,
              };
              setAllFilters(tempFilters);
            }}
            searchValue={searchModuleValue}
            values={moduleValue}
            optList={Object.keys(MODULES)
              .map((module) => ({
                label: module,
                value: module,
              }))
              .filter((module) => {
                return module.value.toLocaleUpperCase().includes(searchModuleValue.toLocaleUpperCase());
              })}
            buttonProps={{
              textStr: "Module",
              sx: {
                border: "none",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  border: "none",
                },
              },
              variant: "outlined",
              startIcon: <FilterIcon color="#394458" />,
            }}
          />
        </Grid>
      </Grid>

      <div
        style={{
          padding: 16,
        }}
      >
        <ActiveFilters
          isFilters={allFilters}
          setIsFilters={(values) => {
            setModuleValue(values?.module ? values.module : []);
            setAllFilters(values);
          }}
          initialFilters={{}}
        />
      </div>
      <Spacer height={22} />
      <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={styles.gridSwitcher}>
        <div className={styles.containerSwitcher}>
          <div className={`${styles.swicth} ${isActive === 1 && styles.switchActive}`} onClick={() => setIsActive(1)}>
            All
          </div>
          <div className={`${styles.swicth} ${isActive === 2 && styles.switchActive}`} onClick={() => setIsActive(2)}>
            Unread
            <div className={styles.noti}>{getTotalRead(false)}</div>
          </div>
        </div>
      </Grid>
      <div className={styles.content}>
        {isFilteredData
          .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
          .map((item, index) => {
            return (
              <>
                {(index === 0 || isFilteredData[index].dateString !== isFilteredData[index - 1].dateString) && (
                  <div className={styles.month}>{item.dateString}</div>
                )}
                <div
                  className={styles.taskCard}
                  key={"notification" + index}
                  onClick={() => {
                    handleNotificationClick(item);
                  }}
                >
                  <div>
                    <span className={styles.bold}>{item.userFullName ? item.userFullName : item.title}</span>
                    <Spacer height={16} />
                    <div className={styles.cardAction}>{item.content}</div>
                  </div>
                  <div className={styles.divProduct}>{item.module}</div>
                  <div className={styles.dateTimeDifference}>{formatDate(item.datetime)}</div>
                  {!item.read && <div className={styles.dotRed}>{DotRed}</div>}
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
};

export default Notifications;
