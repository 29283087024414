import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Box, Fade, useMediaQuery, ClickAwayListener } from "@mui/material";
import styles from "./AboutCabinMonitoring.module.css";
import BreadcrumbsSeparator from "common/images/BreadcrumbsSeparator.svg";
import ImgCabinMonitoringXL from "common/images/CabinMonitoringXL.svg";
import ZebraScanner from "common/images/ZebraScanner.svg";
import ZebraValidated from "common/images/ZebraValidated.svg";
import Benefits1 from "common/images/Benefits-1.svg";
import Benefits2 from "common/images/Benefits-2.svg";
import Benefits3 from "common/images/Benefits-3.svg";
import Benefits4 from "common/images/Benefits-4.svg";
import InAction01 from "common/images/InAction01.svg";
import InAction02 from "common/images/InAction02.png";
import InAction03 from "common/images/InAction03.png";
import InAction04 from "common/images/InAction04.png";
import InAction05 from "common/images/InAction05.png";
import IconCloseWhite from "common/images/Icon-CloseWhite.svg";
import IconArrowRightWhite from "common/images/Icon-ArrowRight-White.svg";
import IconArrowLeftWhite from "common/images/Icon-ArrowLeftWhite.svg";

const AboutCabinMonitoring = () => {
  const isMobile = useMediaQuery("(max-width:900px)");
  const [render] = useState(true);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const [info, setInfo] = useState("");

  const Info01 = "ADE engineer performing RFID scanning for A320 aircraft";
  const Info02 = "ELEVADE FLEET: Cabin Monitoring module powered by Zebra";
  const Info03 = "Life vest scanning in progress";
  const Info04 = "Life vest scanning in progress";
  const Info05 = "Uploading inspection report to cloud";

  const Breadcrumbs = () => {
    return (
      <>
        <Typography sx={{ fontSize: "14px" }}>
          <Link to={"/"} style={{ color: "#838383", marginRight: "4px" }}>
            Home
          </Link>{" "}
          <img src={BreadcrumbsSeparator} alt="" />
          <span style={{ marginLeft: "8px", color: "#333333" }}>Cabin Monitoring</span>
        </Typography>
      </>
    );
  };
  const BackgroundImage = () => {
    return (
      <>
        <div className={styles.backgroundImage}>
          <img src={ImgCabinMonitoringXL} alt="" />
        </div>
      </>
    );
  };

  const HeaderMainContent = () => {
    return (
      <>
        <div style={{ position: "relative", zIndex: 1 }}>
          <Grid container columns={1}>
            <Grid item xs={1} className={styles.mainTitle}>
              Cabin Monitoring with RFID Technology
            </Grid>
            <Grid item className={styles.subMainContent}>
              Introducing our next-generation RFID mobile solution for aircraft life vests, {!isMobile ? <br /> : ""}
              powered by{" "}
              <span className={styles.bold}>
                Zebra Technologies. <span className={styles.gradient}>Be safer, faster</span>
              </span>
            </Grid>
            <Grid item xs={1} sx={{ display: isMobile ? "block" : "flex", justifyContent: "center" }}>
              <img src={ZebraScanner} alt="" style={{ zIndex: 1, marginLeft: isMobile ? "38px" : "0px" }} />
              <img src={ZebraValidated} alt="" style={{ zIndex: 1, marginLeft: "36px" }} />
              <div
                style={{
                  maxWidth: "650px",
                  marginLeft: isMobile ? "0px" : "32px",
                  marginTop: "44px",
                  fontSize: "14px",
                  zIndex: 2,
                }}
              >
                RFID scanning technology helps airlines to improve the accuracy and efficiency of life vest inspections,
                while ensuring passenger safety and compliance.{" "}
                <span className={styles.bold}>ELEVADE FLEET Cabin Monitoring with RFID technology solution</span> is the{" "}
                <span className={styles.bold}>Zebra-validated application; reliable and trustworthy,</span> for your
                peace of mind.
              </div>
            </Grid>
          </Grid>
          {!isMobile && <BackgroundImage />}
        </div>
      </>
    );
  };

  const Benefit = () => {
    return (
      <>
        <div style={{ padding: "70px 0px" }}>
          <Grid container columns={2}>
            <Grid item xs={2} sx={{ fontSize: "14px", color: "#838383", fontFamily: "Gotham-Medium" }}>
              BENEFITS
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={1} xl={1} sx={{ paddingTop: "44px" }}>
              <Grid container columns={6}>
                <Grid item xs={1}>
                  <img src={Benefits1} alt="" />
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{ paddingRight: isMobile ? "0px" : "70px", paddingLeft: isMobile ? "40px" : "0px" }}
                >
                  <Typography sx={{ fontFamily: "Gotham-Medium", fontSize: "24px", paddingBottom: "10px" }}>
                    Faster and accurate inspections
                  </Typography>
                  <Typography sx={{ fontFamily: "Gotham-Book", fontSize: "14px" }}>
                    By using RFID scanning technology, airlines can quickly and easily check the{" "}
                    <span className={styles.bold}>availability and validity dates</span> of life vests. This saves
                    airlines a significant amount of time and ensures that life vests are always accessible when needed.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={1} xl={1} sx={{ paddingTop: "44px" }}>
              <Grid container columns={6}>
                <Grid item xs={1}>
                  <img src={Benefits2} alt="" />
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{ paddingRight: isMobile ? "0px" : "90px", paddingLeft: isMobile ? "40px" : "0px" }}
                >
                  <Typography sx={{ fontFamily: "Gotham-Medium", fontSize: "24px", paddingBottom: "10px" }}>
                    Enhanced safety and compliance
                  </Typography>
                  <Typography sx={{ fontFamily: "Gotham-Book", fontSize: "14px" }}>
                    Previously, inspecting life vests on an aircraft{" "}
                    <span className={styles.bold}>was a time-consuming process</span> that required maintenance staff to
                    manually inspect each seat. With the implementation of ELEVADE FLEET Cabin Monitoring, this task can
                    now be completed more efficiently, taking only 2 minutes to check the entire A320 aircraft. This not
                    only <span className={styles.bold}>enhances the experience for maintenance personnel</span> but also{" "}
                    <span className={styles.bold}>saves valuable time,</span> leading to{" "}
                    <span className={styles.bold}>improved passenger safety</span> and{" "}
                    <span className={styles.bold}>adherence to regulations.</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={1} xl={1} sx={{ paddingTop: "44px" }}>
              <Grid container columns={6}>
                <Grid item xs={1}>
                  <img src={Benefits3} alt="" />
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{ paddingRight: isMobile ? "0px" : "70px", paddingLeft: isMobile ? "40px" : "0px" }}
                >
                  <Typography sx={{ fontFamily: "Gotham-Medium", fontSize: "24px", paddingBottom: "10px" }}>
                    Powered by Zebra Technologies
                  </Typography>
                  <Typography sx={{ fontFamily: "Gotham-Book", fontSize: "14px" }}>
                    The ELEVADE FLEET Cabin Monitoring app is{" "}
                    <span className={styles.bold}>
                      validated by Zebra Technologies through a rigorous set of criteria which resulted in a robust yet
                      user-friendly application.
                    </span>{" "}
                    Zebra's reputation as an industry leader guarantees{" "}
                    <span className={styles.bold}>reliability and scalability, enabling seamless operations.</span> By
                    integrating our app with their RFID technology, we can provide{" "}
                    <span className={styles.bold}>real-time inventory visibility</span> over WiFi and 4G/5G. This
                    partnership assures your organisation a competitive advantage by{" "}
                    <span className={styles.bold}>driving innovation, offering robust support,</span> and harnessing the{" "}
                    <span className={styles.bold}>full potential of RFID technology</span> to achieve business success.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={1} xl={1} sx={{ paddingTop: "44px" }}>
              <Grid container columns={6}>
                <Grid item xs={1}>
                  <img src={Benefits4} alt="" />
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{ paddingRight: isMobile ? "0px" : "90px", paddingLeft: isMobile ? "40px" : "0px" }}
                >
                  <Typography sx={{ fontFamily: "Gotham-Medium", fontSize: "24px", paddingBottom: "10px" }}>
                    Leveraging the ELEVADE Advantage
                  </Typography>
                  <Typography sx={{ fontFamily: "Gotham-Book", fontSize: "14px" }}>
                    The ELEVADE FLEET Cabin Monitoring solution is only one facet of the extensive ELEVADE ecosystem.
                    ELEVADE offers a complete range of services, including{" "}
                    <span className={styles.bold}>
                      people management, technical documentation, immersive learning experiences, and fleet health.
                    </span>{" "}
                    Utilise the full potential of the ELEVADE ecosystem to elevate your organisation today.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const ContactUs = () => {
    const email = "mailto:elevade_support@ade.aero";
    return (
      <>
        <div
          style={{
            width: "100%",
            borderRadius: "32px",
            background: "var(--light-mode-background-colours-base-2-fafafe, #FAFAFE)",
            boxShadow: "4px 8px 24px 0px rgba(57, 68, 88, 0.08)",
            padding: isMobile ? "20px 20px" : "77px 0px",
            marginBottom: isMobile ? "40px" : "140px",
          }}
        >
          <Grid container columns={1}>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
              <Typography sx={{ fontFamily: "Gotham-Medium", fontSize: "24px", paddingBottom: "10px" }}>
                <center>Interested to know more?</center>
              </Typography>
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
              <center>To learn how RFID scanning technology can help your organisation, contact us today!</center>
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
              <Link
                to="#"
                onClick={(e) => {
                  window.location.href = email;
                  e.preventDefault();
                }}
              >
                <button className={styles.button}>
                  <span style={{ color: "white" }}>Contact Us</span>
                </button>
              </Link>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const CabinMonitoringInAction = () => {
    return (
      <>
        <div style={{ padding: isMobile ? "0px 0px" : "0px 180px", position: "relative" }}>
          <Grid container columns={1}>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Typography sx={{ fontFamily: "Gotham-Medium", fontSize: "24px", paddingBottom: "10px" }}>
                Cabin Monitoring in Action
              </Typography>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "flex-start", fontSize: "14px", padding: "24px 0px" }}
            >
              ADE engineers performing a cabin inspection with the RFID scanning technology. Inspections are now
              efficient and painless.
            </Grid>
            <Grid item xs={1} sx={{}}>
              <Grid container columns={2}>
                <Grid item xs={2} sx={{ padding: "0px 0px 16px 0px" }}>
                  <div style={{ objectFit: "cover", cursor: "pointer" }} onClick={() => handleOpen(InAction01, Info01)}>
                    <img src={InAction01} alt="" width={"100%"} />
                  </div>
                </Grid>
                <Grid item xs={1} sx={{ padding: "0px 8px 0px 0px" }}>
                  <div style={{ objectFit: "cover", cursor: "pointer" }} onClick={() => handleOpen(InAction02, Info02)}>
                    <img src={InAction02} alt="" width={"100%"} />
                  </div>
                </Grid>
                <Grid item xs={1} sx={{ padding: "0px 0px 0px 8px" }}>
                  <div style={{ objectFit: "cover", cursor: "pointer" }} onClick={() => handleOpen(InAction03, Info03)}>
                    <img src={InAction03} alt="" width={"100%"} />
                  </div>
                </Grid>
                <Grid item xs={1} sx={{ padding: "16px 8px 0px 0px" }}>
                  <div style={{ objectFit: "cover", cursor: "pointer" }} onClick={() => handleOpen(InAction04, Info04)}>
                    <img src={InAction04} alt="" width={"100%"} />
                  </div>
                </Grid>
                <Grid item xs={1} sx={{ padding: "16px 0px 0px 8px" }}>
                  <div style={{ objectFit: "cover", cursor: "pointer" }} onClick={() => handleOpen(InAction05, Info05)}>
                    <img src={InAction05} alt="" width={"100%"} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Preview open={open} setOpen={setOpen} fileLink={file} />
        </div>
      </>
    );
  };

  const Preview = ({ open, setOpen, fileLink }) => {
    const handleClose = () => setOpen(false);

    const style = {
      position: "fixed",
      top: "53%",
      left: "50%",
      width: "70%",
      height: "80%",
      transform: "translate(-50%, -50%)",
    };

    const overlay = {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      backdropFilter: "blur(2px)",
      top: 0,
      right: 0,
      width: "100%",
      height: "100%",
      position: "fixed",
      opacity: 1,
      transition: "visibility 0s linear 200ms, opacity 200ms",
      color: "#ffffff",
      zIndex: 9,
    };

    return (
      <>
        {open && (
          <div style={overlay}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box sx={style}>
                  <img
                    src={fileLink}
                    alt={`Preview`}
                    style={{ width: "100%", height: isMobile ? "40%" : "80%", objectFit: "cover" }}
                  />
                  <img
                    src={IconCloseWhite}
                    alt={"icon-close"}
                    style={{ position: "absolute", top: "-30px", right: "0px", cursor: "pointer", zIndex: 9 }}
                    onClick={handleClose}
                  />
                  <div style={{ marginTop: "20px" }}>
                    <Grid container columns={3}>
                      <Grid item xs={0.2} sx={{ display: "flex", justifyContent: "flex-start" }}>
                        <img src={IconArrowLeftWhite} alt="" style={{ cursor: "pointer" }} onClick={handlePrevious} />
                      </Grid>
                      <Grid item xs={2.6} sx={{ display: "flex", justifyContent: "center" }}>
                        {!isMobile ? info : ""}
                      </Grid>
                      <Grid item xs={0.2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <img src={IconArrowRightWhite} alt="" style={{ cursor: "pointer" }} onClick={handleNext} />
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </Box>
            </ClickAwayListener>
          </div>
        )}
      </>
    );
  };

  const handleOpen = (file, info) => {
    setOpen(true);
    setFile(file);
    setInfo(info);
  };

  const handlePrevious = () => {
    if (file === InAction01) {
      setFile(InAction05);
      setInfo(Info05);
    } else if (file === InAction02) {
      setFile(InAction01);
      setInfo(Info01);
    } else if (file === InAction03) {
      setFile(InAction02);
      setInfo(Info02);
    } else if (file === InAction04) {
      setFile(InAction03);
      setInfo(Info03);
    } else if (file === InAction05) {
      setFile(InAction04);
      setInfo(Info04);
    }
  };

  const handleNext = () => {
    if (file === InAction01) {
      setFile(InAction02);
      setInfo(Info02);
    } else if (file === InAction02) {
      setFile(InAction03);
      setInfo(Info03);
    } else if (file === InAction03) {
      setFile(InAction04);
      setInfo(Info04);
    } else if (file === InAction04) {
      setFile(InAction05);
      setInfo(Info05);
    } else if (file === InAction05) {
      setFile(InAction01);
      setInfo(Info01);
    }
  };

  return (
    <>
      <Fade in={render}>
        <div className={styles.container}>
          <Breadcrumbs />
          <HeaderMainContent />
          <Benefit />
          <ContactUs />
          <CabinMonitoringInAction />
        </div>
      </Fade>
    </>
  );
};

export default AboutCabinMonitoring;
