import React from "react";
import { Grid } from "@mui/material";
import styles from "./ActiveFilters.module.css";
import DeleteFilter from "common/images/Icon-Clear-Filter.svg";
import Spacer from "../spacer/Spacer";

const ActiveFilters = ({ isFilters, setIsFilters, key, value, initialFilters }) => {
  function checkAnyKeyHasValue(obj) {
    return Object.values(obj).some((value) => value.length > 0);
  }

  const result = checkAnyKeyHasValue(isFilters);
  const keys = Object.keys(isFilters);

  function handleRemoveFilter(value, key) {
    // if (key === "paxCapacity") value = parseInt(value);
    const updatedChecked = isFilters[key]?.filter((item) => item !== value);
    const updatedFilters = { ...isFilters, [key]: updatedChecked };
    setIsFilters(updatedFilters);
  }

  function handleClearAllFilter() {
    setIsFilters(initialFilters);
  }

  return (
    result && (
      <>
        <div className={styles.container}>
          <Grid container columns={10.2}>
            <Grid
              item
              xs={1}
              sx={{
                fontSize: 14,
                display: "flex",
                alignItems: "center",
              }}
            >
              Active Filter:
            </Grid>
            <Grid item xs={8.6}>
              {keys &&
                keys?.map((key, index) => {
                  return isFilters[key]?.map((value) => {
                    return (
                      <div className={styles.filterItem} key={index}>
                        {value}
                        <img
                          src={DeleteFilter}
                          alt="clear item"
                          className={styles.deleteFilter}
                          onClick={() => handleRemoveFilter(value, key)}
                        />
                      </div>
                    );
                  });
                })}
            </Grid>
            <Grid
              item
              xs={0.6}
              sx={{
                fontFamily: "Gotham-Medium",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                //   paddingTop: "8px",
              }}
            >
              <span style={{ cursor: "pointer" }} onClick={() => handleClearAllFilter()}>
                Clear All
              </span>
            </Grid>
          </Grid>
        </div>
        <Spacer height={8} />
        <div style={{ fontFamily: "Gotham-Book", fontSize: "12px", color: "#747474" }}>
          *Data displayed based on active filter
        </div>
      </>
    )
  );
};

export default ActiveFilters;
