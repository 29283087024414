import React from "react";
import { Tooltip, styled, tooltipClasses } from "@mui/material";

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme, ...props }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: props.bgcolor || "#FFFEE8",
      color: props.color || "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: props.fontSize || 11,
      padding: "8px",
      borderRadius: "8px",
      fontFamily: "Gotham-Book",
    },
  })
);

export default LightTooltip;
