import React from "react";

export function TrainingDashboard({ stroke, width, height }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path
        d="M5.3125 18.5938C7.0384 18.5938 8.4375 17.1946 8.4375 15.4688V13.9062M14.6875 18.5938C12.9616 18.5938 11.5625 17.1946 11.5625 15.4688V13.9062H2.34375C1.48078 13.9062 0.78125 13.2066 0.78125 12.3438V2.96875C0.78125 2.10578 1.48078 1.40625 2.34375 1.40625H17.6562C18.5192 1.40625 19.2188 2.10578 19.2188 2.96875V12.3438C19.2188 13.2066 18.5192 13.9062 17.6562 13.9062H14.6875M3.75 18.5938H16.25M3.90625 7.65625H6.875M3.90625 4.53125H6.875M3.90625 10.7812H6.875M16.0938 9.21875C16.0938 10.0817 15.3942 10.7812 14.5312 10.7812H11.5625C10.6996 10.7812 10 10.0817 10 9.21875V6.09375C10 5.23082 10.6996 4.53125 11.5625 4.53125H14.5312C15.3942 4.53125 16.0938 5.23082 16.0938 6.09375V9.21875Z"
        stroke={stroke}
        stroke-width="1.3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function LicenseRenewal({ stroke, width, height }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 22" fill="none">
      <path
        d="M3.39944 12.2109H2.79691C1.69234 12.2109 0.796906 11.3155 0.796906 10.2109V3.58594C0.796906 2.48137 1.69234 1.58594 2.79691 1.58594H15.2032C16.3077 1.58594 17.2032 2.48137 17.2032 3.58594V10.2109C17.2032 11.3155 16.3077 12.2109 15.2032 12.2109H14.6006M4.00001 5H14M10.0137 16.3125L11.0273 16.8984C10.6148 17.6057 9.8625 18.0703 9 18.0703C8.13774 18.0703 7.38535 17.6059 6.9727 16.8984M7.98635 15.1406L6.97268 14.5547C7.38678 13.8447 8.1403 13.3828 9.00003 13.3828C9.85807 13.3828 10.6124 13.8432 11.0273 14.5547M14.4453 8.69531H10.9297M13.6875 15.7266C13.6875 18.3154 11.5888 20.4141 9.00001 20.4141C6.41118 20.4141 4.31251 18.3154 4.31251 15.7266C4.31251 13.1377 6.41118 11.0391 9.00001 11.0391C11.5888 11.0391 13.6875 13.1377 13.6875 15.7266Z"
        stroke={stroke}
        stroke-width="1.3"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  );
}

export function LearningLibrary({ stroke, width, height }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 22" fill="none">
      <path
        d="M13.5104 12.8727C11.6695 9.61043 6.6068 11.6017 6.6068 11.6017V19.143C6.6068 19.143 11.6695 17.1518 13.5104 20.4141M13.5104 12.8727V20.4141M13.5104 12.8727C15.3514 9.61047 20.4141 11.6017 20.4141 11.6017V19.143C20.4141 19.143 15.3514 17.1518 13.5104 20.4141M11 16.1398C10.3716 16.0112 9.72 16.0057 9.11719 16.0605M16.0208 16.1398C16.6493 16.0112 17.3008 16.0057 17.9036 16.0605M11 13.6261C10.3716 13.4974 9.72 13.4919 9.11719 13.5466M16.0208 13.6261C16.6493 13.4974 17.3008 13.4919 17.9036 13.5466M4.60676 12.8828H3.58594C2.48137 12.8828 1.58594 11.9874 1.58594 10.8828V3.58594C1.58594 2.48137 2.48137 1.58594 3.58594 1.58594H15.9036C17.0082 1.58594 17.9036 2.48137 17.9036 3.58594V9M8.48957 8.11719V4.35156L11.9414 6.23438L8.48957 8.11719Z"
        stroke={stroke}
        stroke-width="1.3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function CourseEnrollment({ stroke, width, height }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path
        d="M16.7437 12.9688C16.7437 12.6098 16.4527 12.3188 16.0938 12.3188C15.7348 12.3188 15.4438 12.6098 15.4438 12.9688H16.7437ZM15.4438 19.2188C15.4438 19.5777 15.7348 19.8687 16.0938 19.8687C16.4527 19.8687 16.7437 19.5777 16.7437 19.2188H15.4438ZM12.9688 15.4437C12.6098 15.4437 12.3188 15.7348 12.3188 16.0937C12.3188 16.4527 12.6098 16.7437 12.9688 16.7437V15.4437ZM19.2188 16.7437C19.5777 16.7437 19.8687 16.4527 19.8687 16.0937C19.8687 15.7348 19.5777 15.4437 19.2188 15.4437V16.7437ZM12.9688 19.8687C13.3277 19.8687 13.6187 19.5777 13.6187 19.2188C13.6187 18.8598 13.3277 18.5688 12.9688 18.5688V19.8687ZM2.10207 13.2826L2.53109 13.7709L2.5311 13.7709L2.10207 13.2826ZM15.4438 12.9688V19.2188H16.7437V12.9688H15.4438ZM12.9688 16.7437H19.2188V15.4437H12.9688V16.7437ZM5.65 5.78125C5.65 3.37883 7.59758 1.43125 10 1.43125V0.13125C6.87961 0.13125 4.35 2.66086 4.35 5.78125H5.65ZM10 1.43125C12.4024 1.43125 14.35 3.37883 14.35 5.78125H15.65C15.65 2.66086 13.1204 0.13125 10 0.13125V1.43125ZM14.35 5.78125C14.35 8.18367 12.4024 10.1312 10 10.1312V11.4312C13.1204 11.4312 15.65 8.90164 15.65 5.78125H14.35ZM10 10.1312C7.59758 10.1312 5.65 8.18367 5.65 5.78125H4.35C4.35 8.90164 6.87961 11.4312 10 11.4312V10.1312ZM12.9688 18.5688H2.34375V19.8687H12.9688V18.5688ZM2.34375 18.5688C1.83981 18.5688 1.43125 18.1602 1.43125 17.6562H0.13125C0.13125 18.8782 1.12184 19.8687 2.34375 19.8687V18.5688ZM1.43125 17.6562V16.2109H0.13125V17.6562H1.43125ZM1.43125 16.2109C1.43125 15.2757 1.83114 14.3859 2.53109 13.7709L1.67305 12.7943C0.689565 13.6584 0.13125 14.9053 0.13125 16.2109H1.43125ZM2.5311 13.7709C3.82585 12.6333 6.26789 11.4312 10 11.4312V10.1312C5.98484 10.1312 3.22853 11.4276 1.67304 12.7943L2.5311 13.7709Z"
        fill={stroke}
      />
    </svg>
  );
}

export function CreateCourse({ stroke, width, height }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path
        d="M16.4062 12.5781V18.5156M13.4375 15.5469H19.2188M16.4062 9.45312V3.82812C16.4062 2.53371 15.3569 1.48438 14.0625 1.48438H3.125C1.83059 1.48438 0.78125 2.53371 0.78125 3.82812V13.2031C0.78125 14.4975 1.83059 15.5469 3.125 15.5469H10.4688M7.44531 8.69531H3.92969M7.51562 5.69531H4"
        stroke={stroke}
        stroke-width="1.3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
