// src/redux/actions/sidebarActions.js
import { SET_USER_AUTH, SET_USER_PROFILE, LOGOUT } from "./actionTypes";

export const setUserAuth = (profile) => ({
  type: SET_USER_AUTH,
  payload: profile,
});

export const setUserProfile = (profile) => ({
  type: SET_USER_PROFILE,
  payload: profile,
});

export const logout = () => ({
  type: LOGOUT,
});
