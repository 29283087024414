import React, { useState } from "react";
import styles from "./QuickAccess.module.css";
import { Grid } from "@mui/material";
import IconActionableItems from "common/images/Icon-ActionableItems.svg";
import IconProductDocument from "common/images/Icon-ProductDocument.svg";
import IconProductFleet from "common/images/Icon-ProductFleet.svg";
import IconProductPeople from "common/images/Icon-ProductPeople.svg";
import IconProductLearning from "common/images/Icon-ProductLearning.svg";
import { DigitalLibrary, MyCollections, Publishing, PublicationMgmt } from "common/images/productIcons/Document";
import {
  ACMonitoring,
  Inbox,
  RepetitiveDefect,
  ECAMDispatchAlert,
  DASR,
  ScheduleTask,
  TaskAssignment,
  CabinMonitoring,
} from "common/images/productIcons/Fleet";
import { MyTimesheet, ApplyOvertime, MyTask, MyWorkSchedule } from "common/images/productIcons/People";
import {
  TrainingDashboard,
  LicenseRenewal,
  LearningLibrary,
  CourseEnrollment,
  CreateCourse,
} from "common/images/productIcons/Learning";
import LightTooltip from "common/components/tooltip/Tooltip";
import { useSelector } from "react-redux";

const QuickAccess = () => {
  const { userDetails } = useSelector((state) => state.user);
  const userPermission = userDetails.permission;
  const [isHover, setIsHover] = useState(null);
  const URL = window.location.origin;

  let colorActive = "#333333";
  let colorDisabled = "#C3C3C3";
  let colorHover = "#0ADDDD";

  const [isModule] = useState([
    // {
    //   product: "Document",
    //   disable: false,
    //   icon: IconProductDocument,
    //   module: [
    //     {
    //       name: "Digital Library",
    //       url: `${process.env.REACT_APP_URL_DOCUMENT}/tech-publication/digital-library`,
    //       isExternal: process.env.REACT_APP_URL_DOCUMENT_IS_EXTERNAL,
    //       disable: !userPermission.some((permission) => permission.code === "MANAGE_DIGITAL_LIBRARY"),
    //       icon: <DigitalLibrary stroke={colorActive} width="24" height="24" />,
    //       iconDisabled: <DigitalLibrary stroke={colorDisabled} width="24" height="24" />,
    //       iconHover: <DigitalLibrary stroke={colorHover} width="24" height="24" />,
    //     },
    //     {
    //       name: "My Collections",
    //       url: `${process.env.REACT_APP_URL_DOCUMENT}/tech-publication/my-collections/favourites`,
    //       isExternal: process.env.REACT_APP_URL_DOCUMENT_IS_EXTERNAL,
    //       disable: !userPermission.some((permission) => permission.code === "MANAGE_DIGITAL_LIBRARY"),
    //       icon: <MyCollections stroke={colorActive} width="24" height="24" />,
    //       iconDisabled: <MyCollections stroke={colorDisabled} width="24" height="24" />,
    //       iconHover: <MyCollections stroke={colorHover} width="24" height="24" />,
    //     },
    //     {
    //       name: "Publishing",
    //       url: `${process.env.REACT_APP_URL_DOCUMENT}/tech-publication/publishing`,
    //       isExternal: process.env.REACT_APP_URL_DOCUMENT_IS_EXTERNAL,
    //       disable: !userPermission.some((permission) => permission.code === "MANAGE_PUBLISHING"),
    //       icon: <Publishing stroke={colorActive} width="24" height="24" />,
    //       iconDisabled: <Publishing stroke={colorDisabled} width="24" height="24" />,
    //       iconHover: <Publishing stroke={colorHover} width="24" height="24" />,
    //     },
    //     {
    //       name: "Publication Mgmt.",
    //       url: `${process.env.REACT_APP_URL_DOCUMENT}/settings/publication-management/publication-category`,
    //       isExternal: process.env.REACT_APP_URL_DOCUMENT_IS_EXTERNAL,
    //       disable: !userPermission.some((permission) => permission.code === "MANAGE_DISTRIBUTION"),
    //       icon: <PublicationMgmt stroke={colorActive} width="24" height="24" />,
    //       iconDisabled: <PublicationMgmt stroke={colorDisabled} width="24" height="24" />,
    //       iconHover: <PublicationMgmt stroke={colorHover} width="24" height="24" />,
    //     },
    //   ],
    // },
    {
      product: "Fleet",
      disable: false,
      icon: IconProductFleet,
      module: [
        {
          name: "AC Monitoring",
          url: `${process.env.REACT_APP_URL_FLEET}/aircraft-monitoring`,
          isExternal: process.env.REACT_APP_URL_FLEET_IS_EXTERNAL,
          disable: !userPermission.some((permission) => permission.code === "ACM-002"),
          icon: <ACMonitoring stroke={colorActive} width="24" height="24" />,
          iconDisabled: <ACMonitoring stroke={colorDisabled} width="24" height="24" />,
          iconHover: <ACMonitoring stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "Inbox",
          url: `${process.env.REACT_APP_URL_FLEET}/inbox`,
          isExternal: process.env.REACT_APP_URL_FLEET_IS_EXTERNAL,
          disable: !userPermission.some((permission) => permission.code === "ACM-003"),
          icon: <Inbox stroke={colorActive} width="24" height="24" />,
          iconDisabled: <Inbox stroke={colorDisabled} width="24" height="24" />,
          iconHover: <Inbox stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "Repetitive Defect",
          url: `${process.env.REACT_APP_URL_FLEET}/repetitive-defect-database`,
          isExternal: process.env.REACT_APP_URL_FLEET_IS_EXTERNAL,
          disable: !userPermission.some((permission) => permission.code === "ACM-006"),
          icon: <RepetitiveDefect stroke={colorActive} width="24" height="24" />,
          iconDisabled: <RepetitiveDefect stroke={colorDisabled} width="24" height="24" />,
          iconHover: <RepetitiveDefect stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "ECAM Dispatch Alert",
          url: `${process.env.REACT_APP_URL_FLEET}/email-alert-management`,
          isExternal: process.env.REACT_APP_URL_FLEET_IS_EXTERNAL,
          disable: !userPermission.some((permission) => permission.code === "ACM-006"), // TODO
          icon: <ECAMDispatchAlert stroke={colorActive} width="24" height="24" />,
          iconDisabled: <ECAMDispatchAlert stroke={colorDisabled} width="24" height="24" />,
          iconHover: <ECAMDispatchAlert stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "DASR",
          url: `${process.env.REACT_APP_URL_FLEET}/dasr`,
          isExternal: process.env.REACT_APP_URL_FLEET_IS_EXTERNAL,
          disable: !userPermission.some((permission) => permission.code === "DASR-001"),
          icon: <DASR stroke={colorActive} width="24" height="24" />,
          iconDisabled: <DASR stroke={colorDisabled} width="24" height="24" />,
          iconHover: <DASR stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "Scheduled Task",
          url: `${process.env.REACT_APP_URL_FLEET}/schedule-task`,
          isExternal: process.env.REACT_APP_URL_FLEET_IS_EXTERNAL,
          disable: !userPermission.some((permission) => permission.code === "TEMP_SCHEDULEDCHECK"),
          icon: <ScheduleTask stroke={colorActive} width="24" height="24" />,
          iconDisabled: <ScheduleTask stroke={colorDisabled} width="24" height="24" />,
          iconHover: <ScheduleTask stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "Task Assignment",
          url: `${process.env.REACT_APP_URL_FLEET}/dasr/task-assignment`,
          isExternal: process.env.REACT_APP_URL_FLEET_IS_EXTERNAL,
          disable: !userPermission.some((permission) => permission.code === "DASR-013"), //TODO
          icon: <TaskAssignment stroke={colorActive} width="24" height="24" />,
          iconDisabled: <TaskAssignment stroke={colorDisabled} width="24" height="24" />,
          iconHover: <TaskAssignment stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "Cabin Monitoring",
          url: `${process.env.REACT_APP_URL_FLEET}/cabin-monitoring/overview`,
          isExternal: process.env.REACT_APP_URL_FLEET_IS_EXTERNAL,
          disable: !userPermission.some((permission) => permission.code === "CABIN-001"),
          icon: <CabinMonitoring stroke={colorActive} width="24" height="24" />,
          iconDisabled: <CabinMonitoring stroke={colorDisabled} width="24" height="24" />,
          iconHover: <CabinMonitoring stroke={colorHover} width="24" height="24" />,
        },
      ],
    },
    {
      product: "People",
      disable: false,
      icon: IconProductPeople,
      module: [
        {
          name: "My Timesheet",
          url: `${process.env.REACT_APP_URL_PEOPLE}/attendance`,
          isExternal: process.env.REACT_APP_URL_PEOPLE_IS_EXTERNAL,
          disable: !userPermission.some((permission) => permission.code === "people-atm-001"),
          icon: <MyTimesheet stroke={colorActive} width="24" height="24" />,
          iconDisabled: <MyTimesheet stroke={colorDisabled} width="24" height="24" />,
          iconHover: <MyTimesheet stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "Apply Overtime",
          url: `${process.env.REACT_APP_URL_PEOPLE}/overtime`,
          isExternal: process.env.REACT_APP_URL_PEOPLE_IS_EXTERNAL,
          disable: !userPermission.some((permission) => permission.code === "people-atm-008"),
          icon: <ApplyOvertime stroke={colorActive} width="24" height="24" />,
          iconDisabled: <ApplyOvertime stroke={colorDisabled} width="24" height="24" />,
          iconHover: <ApplyOvertime stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "My Task",
          url: `#`,
          isExternal: process.env.REACT_APP_URL_PEOPLE_IS_EXTERNAL,
          disable: !userPermission.some((permission) => permission.code === "PPL-003"),
          icon: <MyTask stroke={colorActive} width="24" height="24" />,
          iconDisabled: <MyTask stroke={colorDisabled} width="24" height="24" />,
          iconHover: <MyTask stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "My Work Schedule",
          url: `${process.env.REACT_APP_URL_PEOPLE}/work-schedule-manage`,
          isExternal: process.env.REACT_APP_URL_PEOPLE_IS_EXTERNAL,
          disable: !userPermission.some((permission) => permission.code === "people-atm-003"),
          icon: <MyWorkSchedule stroke={colorActive} width="24" height="24" />,
          iconDisabled: <MyWorkSchedule stroke={colorDisabled} width="24" height="24" />,
          iconHover: <MyWorkSchedule stroke={colorHover} width="24" height="24" />,
        },
      ],
    },
    {
      product: "Learning",
      disable: true,
      icon: IconProductLearning,
      module: [
        {
          name: "Training Dashboard",
          url: `${process.env.REACT_APP_URL_LEARNING}`,
          isExternal: process.env.REACT_APP_URL_LEARNING_IS_EXTERNAL,
          disable: true,
          icon: <TrainingDashboard stroke={colorActive} width="24" height="24" />,
          iconDisabled: <TrainingDashboard stroke={colorDisabled} width="24" height="24" />,
          iconHover: <TrainingDashboard stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "License Renewal",
          url: `${process.env.REACT_APP_URL_LEARNING}`,
          isExternal: process.env.REACT_APP_URL_LEARNING_IS_EXTERNAL,
          disable: true,
          icon: <LicenseRenewal stroke={colorActive} width="24" height="24" />,
          iconDisabled: <LicenseRenewal stroke={colorDisabled} width="24" height="24" />,
          iconHover: <LicenseRenewal stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "Learning Library",
          url: `${process.env.REACT_APP_URL_LEARNING}`,
          isExternal: process.env.REACT_APP_URL_LEARNING_IS_EXTERNAL,
          disable: true,
          icon: <LearningLibrary stroke={colorActive} width="24" height="24" />,
          iconDisabled: <LearningLibrary stroke={colorDisabled} width="24" height="24" />,
          iconHover: <LearningLibrary stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "Course Enrollment",
          url: `${process.env.REACT_APP_URL_LEARNING}`,
          isExternal: process.env.REACT_APP_URL_LEARNING_IS_EXTERNAL,
          disable: true,
          icon: <CourseEnrollment stroke={colorActive} width="24" height="24" />,
          iconDisabled: <CourseEnrollment stroke={colorDisabled} width="24" height="24" />,
          iconHover: <CourseEnrollment stroke={colorHover} width="24" height="24" />,
        },
        {
          name: "Create Course",
          url: `${process.env.REACT_APP_URL_LEARNING}`,
          isExternal: process.env.REACT_APP_URL_LEARNING_IS_EXTERNAL,
          disable: true,
          icon: <CreateCourse stroke={colorActive} width="24" height="24" />,
          iconDisabled: <CreateCourse stroke={colorDisabled} width="24" height="24" />,
          iconHover: <CreateCourse stroke={colorHover} width="24" height="24" />,
        },
      ],
    },
  ]);

  const Module = ({ module }) => {
    if (module.length > 0) {
      return module.map((value, index) => {
        return (
          <LightTooltip
            title={
              value.disable ? (
                <div>
                  This feature is currently
                  <br />
                  unavailable to you
                </div>
              ) : (
                ""
              )
            }
            followCursor
            placement="right"
            bgcolor="rgba(51, 51, 51, 0.90)"
            color="#FFFFFF"
            fontSize="10"
            key={index}
          >
            <div
              className={`${value.disable ? styles.moduleDisabled : styles.module}`}
              key={index}
              onMouseEnter={() => handleMouseEnter(value.name, value.disable)}
              onMouseLeave={() => handleMouseLeave()}
              onClick={() => handleClick(value)}
            >
              {isHover === value.name ? value.iconHover : value.disable ? value.iconDisabled : value.icon}
              <div className={styles.moduleName}>{value.name}</div>
            </div>
          </LightTooltip>
        );
      });
    }
  };

  function handleMouseEnter(module, disable) {
    if (!disable) setIsHover(module);
  }

  function handleMouseLeave() {
    setIsHover(null);
  }

  function handleClick(module) {
    if (!module.disable) {
      module.isExternal ? window.location.replace(module.url) : window.location.replace(URL + module.url);
    }
  }

  return (
    <div className={styles.container}>
      <Grid container columns={2}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={styles.contentTitle}>
          <div className={styles.divImg}>
            <img src={IconActionableItems} alt="icon-actionable" height={17} />
          </div>
          <div className={styles.divTitle}>Quick Access</div>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={styles.content}>
          {isModule.map((module, index) => {
            return (
              <div className={styles.containerProduct} key={index}>
                <div className={styles.divProductTitle}>
                  <div className={styles.divImgProduct}>
                    <img src={module?.icon} alt={module.product} height={17} />
                  </div>
                  <div className={styles.divTitleName}>{module.product}</div>
                </div>
                <div className={styles.divModule}>
                  <Module module={module.module} />
                </div>
              </div>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default QuickAccess;
