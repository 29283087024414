import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import styles from "./MultiSelect.module.css";
import IconArrow from "common/images/Icon-ArrowUpGrey.svg";
import IconDelete from "common/images/Icon-X-Circle.svg";

const MultiSelect = ({ value, options, placeholder, onChange, ...props }) => {
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options ?? []}
      limitTags={3}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            label={option.label}
            {...getTagProps({ index })}
            style={{ background: "white", border: "1px solid #00A8E8", height: 24, fontSize: 10 }}
            deleteIcon={<img src={IconDelete} style={{ stroke: "black" }} />}
            size="small"
          />
        ));
      }}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          style={{
            fontSize: 14,
            color: selected ? "#088FD1" : "#333333",
            backgroundColor: selected ? "#F8FAFF" : "#fff",
          }}
        >
          <Checkbox
            checkedIcon={
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="16" height="16" rx="4" fill="#088FD1" />
                <path
                  d="M11.4667 4.80029L6.66667 9.6003L4 7.1003"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            icon={
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke="#94A3B8" />
              </svg>
            }
            checked={selected}
            sx={{
              padding: "0px",
              marginRight: "10px",
              marginLeft: "16px",
              color: "#94A3B8",
              "&.Mui-checked": {
                // color: '#94A3B8',
                color: "#088FD1",
              },
            }}
          />
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              // padding: "7px 16px",
              "& fieldset": {
                borderColor: "#e8e8e8",
              },
              // "&:hover fieldset": {
              //   borderColor: "white",
              // },
              "&.Mui-focused fieldset": {
                borderColor: "#088FD1",
              },
            },
            "& .MuiInputBase-input": {
              fontSize: 14,
            },
          }}
          size="small"
          placeholder={placeholder}
        />
      )}
      popupIcon={<img src={IconArrow} style={{ transform: "rotate(180deg)" }} />}
      value={value}
      {...props}
    />
  );
};

export default MultiSelect;
