// src/redux/rootReducer.js
import { combineReducers } from "redux";
import sidebarReducer from "./reducers/sidebarReducer";
import userReducer from "./reducers/userReducer";
import tokenReducer from "./reducers/tokenReducer";

const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  user: userReducer,
  token: tokenReducer,
});

export default rootReducer;
