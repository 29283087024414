import React, { useEffect, useState } from "react";
import styles from "./MessageBar.module.css";
import IconUpdateimg from "common/images/Icon-Update.svg";
import IconChevronDisabled from "common/images/chevron-left.svg";
import IconChevronActive from "common/images/chevron-right.svg";
import IconClose from "common/images/Icon-CloseWhite.svg";
import IconLine from "common/images/Line 5075.svg";
import { Fade } from "@mui/material";

const MessageBar = ({ ...props }) => {
  const [isId, setIsId] = useState(0);

  const [isMessage] = useState([
    {
      data: "Don't let your system be a dinosaur! Update and stay relevant.",
      url: "http://localhost:3000",
      urlTitle: "This is hyperlink",
    },
    {
      data: "This is second system message for the day",
      url: "",
      urlTitle: "",
    },
    {
      data: "This is third system message for the day",
      url: "",
      urlTitle: "",
    },
  ]);

  useEffect(() => {
    // if (isMessage.length > 0) props.setIsVisible(true);
    // eslint-disable-next-line
  }, [isMessage]);

  function handleNext() {
    let currentId = isId;
    let nextId = currentId + 1;
    //check condition if equal to isMessage.length
    if (currentId === isMessage.length - 1) setIsId(currentId);
    else setIsId(nextId);
  }
  function handlePrev() {
    let currentId = isId;
    let nextId = currentId - 1;
    //check condition if equal to 0
    if (currentId === 0) setIsId(currentId);
    else setIsId(nextId);
  }

  function getIcon(arrow) {
    if (isId === 0 && arrow === "previous") {
      return (
        <img src={IconChevronDisabled} alt="icon-non-actionable-left" height={16} className={styles.disableCursor} />
      );
    } else if (isId === 0 && arrow === "next") {
      return <img src={IconChevronActive} alt="icon-actionable-next" height={16} />;
    } else if (isId <= isMessage.length - 1 && arrow === "previous") {
      return <img src={IconChevronActive} alt="icon-actionable-left" height={16} className={styles.rotate180} />;
    } else if (isId <= isMessage.length - 2 && arrow === "next") {
      return <img src={IconChevronActive} alt="icon-actionable-next" height={16} />;
    } else if (isId <= isMessage.length - 1 && arrow === "next") {
      return (
        <img
          src={IconChevronDisabled}
          alt="icon-non-actionable-next"
          height={16}
          className={styles.rotate180DisableCursor}
        />
      );
    }
  }

  return (
    props.isVisible && (
      <Fade in={props.isVisible}>
        <div className={styles.container} id="messageBar">
          <div className={styles.containerIcon}>
            <div className={styles.IconUpdate}>
              <img src={IconUpdateimg} alt="icon-actionable" height={16} />
            </div>
            <div className={styles.textUpdateContainer}>
              {isMessage[isId]?.data}
              {isMessage[isId]?.url !== "" && (
                <a href={isMessage[isId].url} className={styles.textHyperlinkContainer}>
                  {isMessage[isId].urlTitle}
                </a>
              )}
            </div>
            <div className={styles.containerControl}>
              <div className={styles.containerPagination}>
                <div className={styles.paginationIcon} onClick={() => handlePrev()}>
                  {getIcon("previous")}
                </div>
                <div className={styles.textControlContainer}>{isId + 1}/3</div>
                <div className={styles.paginationIcon} onClick={() => handleNext()}>
                  {getIcon("next")}
                </div>
              </div>
              <img src={IconLine} alt="icon-actionable" className={styles.lineIcon} />
              <span>
                <img
                  src={IconClose}
                  alt="icon-actionable"
                  height={10}
                  className={styles.closeIcon}
                  onClick={() => props.setIsVisible(false)}
                />
              </span>
            </div>
          </div>
        </div>
      </Fade>
    )
  );
};
export default MessageBar;
