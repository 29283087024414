import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import Cookies from "js-cookie";

const RedirectLogin = () => {
  const { userDetails } = useSelector((state) => state.user);
  const token = Cookies.get("elevadeToken");

  React.useEffect(() => {
    if (_.isEmpty(userDetails) || _.isEmpty(token)) window.location.replace("/");
    else window.location.replace("/main");
  }, [userDetails, token]);
};

export default RedirectLogin;
