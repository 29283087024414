import React, { useState } from "react";
import { Box, ClickAwayListener } from "@mui/material";
import DashboardSidebarIcon from "./DashboardSidebarIcon";
import styles from "./Sidebar.module.css";
import { useSelector } from "react-redux";

const DashboardSidebar = () => {
  const drawerWidth = 290;
  const drawerWidthClose = 42;
  const sidebarToggle = false;
  const [openMenu, setOpenMenu] = useState(false);
  const isOpen = useSelector((state) => state.sidebar.isOpen);

  function checkMouseEnter() {
    if (sidebarToggle !== true) {
      setOpenMenu(true);
    }
  }

  function checkMouseLeave() {
    if (sidebarToggle !== true) {
      setOpenMenu(false);
    }
  }

  const handleClickAway = () => {
    if (sidebarToggle) {
      setOpenMenu(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        onMouseEnter={() => {
          checkMouseEnter();
        }}
        onMouseLeave={() => {
          checkMouseLeave();
        }}
        sx={{
          width: isOpen || openMenu ? drawerWidth : drawerWidthClose,
          transition: "width 0.3s ease-in-out",
          backgroundColor: "#fff",
          height: "95%",
          left: "34px",
          position: "fixed",
          padding: "24px 8px",
          marginBottom: "16px",
          overflowY: "hidden",
          borderRight: "1px solid #F1F1F1",
          borderLeft: "1px solid #F1F1F1",
          borderTop: "0px",
        }}
        className={`${styles.onScrollbar} ${styles.macScrollbar}`}
      >
        <DashboardSidebarIcon openMenu={openMenu} isOpen={isOpen} />
      </Box>
    </ClickAwayListener>
  );
};

export default DashboardSidebar;
