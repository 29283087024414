import React from "react";
import { Link } from "react-router-dom";
import RestrictedImage from "common/images/Error-403.svg";
import styles from "./Error.module.css";
import { Grid, useMediaQuery } from "@mui/material";

const Error403 = () => {
  const isWeb = useMediaQuery("(min-width:900px)");
  return (
    <>
      <div className={styles.container} style={{ paddingLeft: !isWeb ? "20px" : "" }}>
        <Grid container columns={2}>
          <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <img
              src={RestrictedImage}
              className={styles.restrictedImage}
              alt="error-403"
              height={!isWeb ? "200px" : ""}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={1} lg={1} xl={1} className={styles.bodyMessage}>
            <p style={{ fontFamily: "Gotham-Bold", fontSize: "24px" }}>
              You don’t have the permission to access this page
            </p>
            <p style={{ fontFamily: "Gotham-Book", fontSize: "16px" }}>
              Your account subscription does not allow access to this page. Please go back to the{" "}
              <Link to="/main" style={{ textDecoration: "underline", color: "#2F77FF" }}>
                homepage
              </Link>
            </p>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Error403;
