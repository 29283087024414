import React, { FC } from "react";

const Spacer = ({ width = 0, height = 0, isInline = false }) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        display: isInline ? "inline-block" : "block",
      }}
    />
  );
};

export default Spacer;
